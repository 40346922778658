import React from "react"
import { Link,graphql,StaticQuery} from "gatsby"

import Layout from "./layout"
//import Image from "../../../components/image"
import SEO from "./seo"
import HeaderSmall from "./headerSmall"
import GuideStarComponentRows from "./guideStarComponentRows2020"
import GuideTermsComponent from "./guideTermsComponent2020"
import GuideTermsComponentRows from "./guideTermsComponentRows2020"
import PropTypes from "prop-types"

const Guide = ({Section,Year,ProductName,Entity,Stars5,Stars4,Stars3}) => {

  // https://schema.org/Guide <- denne bør implementeres etterhvert

  return (
    <Layout>
      <SEO title={"Guide til kjøp av "+ ProductName + " i "+Year}
           description={"Hvilken " + ProductName + " bør en kjøpe basert på eget behov? Oersikt over de beste forsikringene til "+Entity+" som dekker ulike behov best."}
           schema={`
              {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Bestforsikring.no",
                "item": "https://bestforsikring.no"
              },{
                "@type": "ListItem",
                "position": 2,
                "name": "Reiseforsikring",
                "item": "https://bestforsikring.no/`+ProductName.toLowerCase()+`"
              },
              {
                "@type": "ListItem",
                "position": 3,
                "name": "Guide til kjøp av `+ProductName.toLowerCase()+` i `+Year+`",
                "item": "https://bestforsikring.no/`+ProductName.toLowerCase()+`/`+Year+`/guide"
              }]
            }
         `}
      />
      <HeaderSmall siteSection={Section}/>

      <section id="section-content" className="content page-33 moto-section" data-widget="section" data-container="section">
        <div className="moto-widget moto-widget-row row-fixed moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top" data-draggable-disabled>
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}>Vi har laget en guide til kjøp av {ProductName} i {Year}. Guiden tar utgangspunkt i hvilket forsikringsbehov du har:</p></div>
                </div></div>
            </div>
          </div>
        </div>
        <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top">
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saaa" data-visible-on="mobile-v" data-animation>
                  <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>{Stars5.need}</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>{Stars5.description}</p><p className="moto-text_system_7" style={{textAlign: 'center'}}>&nbsp;</p><p className="moto-text_normal" style={{textAlign: 'center'}}>Alle forsikringene med fem stjerner garanterer at følgende vilkår er oppfylt:</p></div>
                </div><div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top" data-draggable-disabled>
                <div className="container-fluid">
                  <GuideTermsComponentRows Terms={Stars5.terms}/>
                </div>
              </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama" data-visible-on="mobile-v" data-animation>
                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}>Følgende selskaper / forsikringsprodukter innen {ProductName} har oppnådd 5&nbsp;stjerner:</p></div>
              </div>
                <GuideStarComponentRows Stars={5} Product={Section} Year={Year}/>
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-zero moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saza" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}><a data-action="popup" data-popup-id={12} className="moto-link" href="#">Hva er / hva betyr defaqto-stjernene?</a></p></div>
                </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="laaa" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>{Stars4.need}</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_system_7" style={{textAlign: 'center'}}>{Stars4.description}</p><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_normal" style={{textAlign: 'center'}}>Alle forsikringene med fire stjerner garanterer at følgende vilkår er oppfylt:</p></div>
              </div><div className="moto-widget moto-widget-row moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="sama" style={{backgroundColor: 'transparent'}} data-bg-position="left top">
                <div className="container-fluid">
                  <GuideTermsComponentRows Terms={Stars4.terms}/>
                </div>
              </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}>Følgende selskaper / forsikringsprodukter innen {ProductName} har oppnådd 4&nbsp;stjerner:</p></div>
              </div>
                <GuideStarComponentRows Stars={4} Product={Section} Year={Year}/>
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-zero moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saza" data-visible-on="mobile-v" data-animation>
                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}><a data-action="popup" data-popup-id={12} className="moto-link" href="#">Hva er / hva betyr defaqto-stjernene?</a></p></div>
                </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="lama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><h3 className="moto-text_system_12" style={{textAlign: 'center'}}>{Stars3.description}</h3><p className="moto-text_system_7" style={{textAlign: 'center'}}><br /></p><p className="moto-text_system_7" style={{textAlign: 'center'}}>{Stars3.need}</p></div>
              </div><div className="moto-widget moto-widget-row moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="aaaa" style={{}} data-bg-position="left top">
                <GuideStarComponentRows Stars={3} Product={Section} Year={Year}/>
              </div>
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-zero moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="saza" data-visible-on="mobile-v" data-animation>
                  <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_normal" style={{textAlign: 'center'}}><a data-action="popup" data-popup-id={12} className="moto-link" href="#">Hva er / hva betyr defaqto-stjernene?</a></p></div>
                </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-large moto-spacing-right-auto moto-spacing-bottom-zero moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="laza" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><p className="moto-text_system_7" style={{textAlign: 'center'}}>En komplett liste av alle vurderte {ProductName}er i markedet finnes på <a href="http://defaqto.no" target="_blank" data-action="url" className="moto-link">defaqto.no</a></p></div>
              </div></div>
            </div>
          </div>
        </div>
        <div className="moto-widget moto-widget-row row-fixed moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-large moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="mala" style={{}} data-bg-position="left top" data-draggable-disabled>
          <div className="container-fluid">
            <div className="row" data-container="container">
              <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
                <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-medium moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="mama" data-visible-on="mobile-v" data-animation>
                  <div className="moto-widget-text-content moto-widget-text-editable"><h2 style={{textAlign: 'center'}} className="moto-text_system_5">Vil du sammenligne priser også?</h2></div>
                </div><div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <div className="moto-widget-text-content moto-widget-text-editable"><p style={{textAlign: 'center'}}><span className="moto-color4_3 moto-text_211">Sammenligningstjenesten Kasko.no lar deg sammenligne både priser og vilkår på bilforsikring fra flere selskaper.</span></p></div>
              </div><div data-widget-id="wid__button__5c9764a4e3084" className="moto-widget moto-widget-button moto-preset-4 moto-align-center moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto  " data-widget="button">
                <a href="https://kasko.no/?refid=bestforsikring" target="_blank" data-action="url" className="moto-widget-button-link moto-size-medium moto-link"><span className="fa moto-widget-theme-icon" /> <span className="moto-widget-button-label">Prøv nå</span></a>
              </div></div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default Guide

Guide.propTypes = {
  Stars5: PropTypes.object,
  Stars4: PropTypes.object,
  Stars3: PropTypes.object,
  Section: PropTypes.string,
  Year: PropTypes.string,
  ProductName: PropTypes.string,
  Entity: PropTypes.string
}

Guide.defaultProps = {
  Stars5: null,
  Stars4: null,
  Stars3: null,
  Section: ``,
  Year: ``,
  ProductName: ``,
  Entity:  ``
}